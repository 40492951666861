
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@primary-color: #0b2d71;
@secondary-color:#0066b2;
// @font-family: "GothamNarrowBook", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family: "GothamNarrowBook", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-size-base: 14px;
@icon-color: #0b2d71;
@table-bg: #f3f4f8;
@table-border-color: #f3f4f8;
@table-header-color: white;
@table-header-bg: @secondary-color;
@table-border-color: @secondary-color;
@input-border-color:@primary-color;
@input-hover-border-color: @primary-color;
@select-border-color:@primary-color;
@label-color: #6B6D6F;
@collapse-header-bg:white;
@menu-dark-bg: @primary-color;
@menu-dark-item-hover-bg: #0066b2;
@menu-dark-color: white;
@card-head-background: #064A91;
@card-head-color: white;
@card-background: white;
@btn-primary-color: white;
@btn-default-border:#0081C5;
@btn-default-color:#0081C5;
@btn-primary-bg: #0081C5;
@success-color: #064A91; 
body {
    padding-top: 0px;
    padding-bottom: 10px; 
    width: 100% !important;
    font-family: "GothamNarrowBook", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    // font-family: "GothamBold",Arial !important;
    background: #DBDCDD;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}
.container{ 
    background: white !important;
}

.div-container{ 
    padding-top: 40px !important;
    padding-right: 40px !important;
    padding-bottom: 20px !important;  
    padding-left: 20px !important;  
    border-radius: .5em !important;
    border: solid 1px #dbdcdd !important;
    background: whitesmoke !important;
    box-shadow: 4px 2px #dbdcdd !important;
    height:fit-content !important;
    width: 100% !important;
}

.btn-button:hover{
    color: white ;
    background: linear-gradient(#0b2d71,#0b2d71);
    background-size: 200% 100% ;
    background-position:left right ;
    transition: 0.6s ;
}
.label-required > label{
    color:red;
}
.label-success-msg{
    font-weight: bold;
    color: green !important;
  }

#site-specific-nav ul li {
    flex: 0 auto  !important;
}

#site-specific-nav ul li a {
    color: #0b2d71 !important;  
}
#site-specific-nav ul li a:hover {
    color: #fff !important;  
}
nav#site-specific-nav{
    background-color: white;
    border: 1px solid lightgray;
    display: block !important;
}
.selectedmenu{
    background-color: #0066b2!important;
}
